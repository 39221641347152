.modal {@include size(0px, 0px); position: relative; z-index: 99; @include trans; display: none;
  &.active {display: block;}

  &--container {width: 100%; position: fixed; overflow-y: hidden; @include full0;
    @include flexbox; @include flow-cn; @include just-center; @include items-center;
  }

  &--bg {width: 100%; position: fixed; @include full0; background-color: black; opacity: 0.8;}
  &--xclose {width: 1.5em; position: absolute; top: 0.5em; right: 0.5em;
    @include tablet {top: 1em; right: 1em;}
    svg {width: 100%; display: block;
      * {stroke: $black;
        @include tablet { stroke: white; }
      }
    }
  }

  &--form {
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    header {
      width: 100%;
      margin-bottom: .2em;
      h2 {
        font-size: 1.75em;
        font-weight: 300;
        margin-bottom: 7px;
      }
      p {
        font-size: 0.75em;
        font-weight: 400;
        color: $grey;
        margin-bottom: .75rem;
        & + p {
          margin: 1em 0;
        }
      }
      @include phone {
        h2 {
          font-size: 1.8em;
          font-weight: 300;
          margin-bottom: 7px;
        }
      }
    }
    form {
      width: 100%;
      margin-bottom: 0px;
      p {
        font-size: 0.75em;
        font-weight: 400;
        padding-bottom: 0px;
        &::before {
          content: "* ";
        }
      }
      label {
        width: 100%;
        margin-bottom: 0.2em;
        // input {}
      }
      > button, > a {
        width: 100%;
        @include padbox(0.5em);
        @include radius(3px);
        background-color: $red;
        color: white;
        text-align: center;
      }

      > .disabled {
        background-color: gray
      }

      > div {
        text-align: center;
        margin: 0;
        a {
          font-size: 1.1em;
          padding-right: 4px;
          color: $blue;
          cursor: pointer;
          @include hovers {
            text-decoration: underline;
          }
        }

        button {
          width: 20%;
          @include padbox(0.5em);
          @include radius(3px);
          background-color: $red;
          color: white;
          text-align: center;
          margin: 1em 0;
          cursor: pointer;
        }
      }
    }

    footer {
      width: 100%;
      text-align: center;

      a, button {
        width: 100%;
        @include padbox(0.5em);
        @include radius(3px);
        background-color: $red;
        color: white;
        text-align: center;
        margin: 1em 0;
      }
    }
    aside {
      width: 100%;
      text-align: center;
      margin: 0.5em 0;
      h6 {
        font-size: 0.75rem;
      }
      ul {
        li {
          font-size: 0.85rem;
        }
      }
      p, a {
        display: inline;
        font-size: 0.9em;
      }

      a {
        color: $blue;

        &:hover {
          text-decoration: underline;
        }
      }

      // &:last-of-type { display: none; }
    }
  }

  &--user {width: 100%; background-color: white; margin: 0 auto; z-index: 1;
    overflow-y: auto;
    @include transform(scale(0)); @include trans;
    @include flexbox; @include flow-rn; @include just-center; @include items-stretch;
    //position: absolute; top: 20%; bottom: auto; left: 0; right: 0;
    //@include tablet {top: 33%;}
    @include desk {width: 74%;}
    &.active {@include transform(scale(1));}
    picture {display: none; width: 50%; overflow: hidden;
      @include tablet {display: block;}
      img { object-fit: cover; object-position: center center; min-height: 100%; min-width: 100%; width: auto; }
    }
    section {width: 100%; @include padbox(.5em);
      @include tablet {width: 50%; padding: 2em;}
    }
  }

  &--register {
    margin-bottom: 0px;
    p {
      padding: 0;
      color: $grey;
    }
    label {
      margin-bottom: 0px;
      padding-bottom: 0px;
      input {
        padding-bottom: 2px;
        padding-top: 2px;
      }
    }
    &-passreq {
      text-align: left !important;
      margin: 0.5rem 0 1rem 0;
      color: $grey;
      font-weight: normal;
      font-size: 0.65rem;
      > * {
        margin: 0.1rem 0;
      }
      > div {
        font-size: 0.7rem;
        font-weight: bold;
        margin-top: .75rem;
      }
      ul {
        li {
          margin: 0.25rem 0;
          &::before {
            content: '';
            display: inline-block;
            width: 7px;
            height: 7px;
            color: $blue;
            background-color: $blue;
            border-radius: 100%;
            margin-right: 5px;
          }
        }
      }
    }
    &-comply {
      margin: 0 0 .5rem 0 !important;
      font-size: 0.65rem;
      text-align: left !important;
      color: $grey;
      font-weight: normal;
      label {
        width: auto !important;
        margin-bottom: 0 !important;
        padding-right: 4px;
        input {
          -webkit-appearance: checkbox;
          -moz-appearance: checkbox;
          appearance: auto;
          border: inherit;
          width: auto !important;
          display: inline-block;
          margin-right: 6px;
          vertical-align: text-top;
        }
      }
      > a {
        color: $blue;
        padding: 0;
        width: auto;
        font-weight: bold;
      }
    }
  }

  &--customer {width: 90%; margin: 0 auto; z-index: 1; @include trans; @include transform(scale(0));
    @include flexbox; @include flow-rn; @include just-center; @include items-center;
    position: absolute; top: 20%; bottom: auto; left: 0; right: 0;
    @include tablet {width: 60%; top: 33%;}
    @include desk {width: 48%;}
    &.active {@include transform(scale(1));}
    .back {@include padbox(0.5em); color: $blue; font-size: 0.9em;
      position: absolute; top: 0.5em; left: 0.5em;
      @include tablet {top: 1em; left: 1em;}
      &::before {content: "\f104"; font-family: $icon; display: inline-block; padding-right: 6px;}
      &:hover {text-decoration: underline;}
    }
  }

  &--number {width: 100%; @include padbox(1.5em); text-align: center; position: relative;
    @include tablet {padding: 2.5em;}
    header {padding: 1em 0;
      h2 {font-size: 1.5em; font-weight: 300; padding-bottom: 10px;
        @include tablet {font-size: 1.875em;}
      }
      p {font-size: 1em; color: $grey;}
    }
    form {width: 100%; padding: 1em 0;
      label {width: 100%; position: relative; margin-bottom: 0.5em;
        @include tablet {width: 80%;}
        // input {}
        i {position: absolute; top: 0.5em; right: 0.5em;
          svg {@include size(20px, 20px); display: block;}
        }
      }
      p {font-size: 0.9em; font-weight: 400;}
    }
    aside {width: 100%; text-align: center;
      a {@include padbox(0.5em); background-color: $blue; color: white; @include radius(3px); width: 260px;
        // &:hover {}
      }
    }
  }

  &--tipo-compra {width: 90%; margin: 0 auto; z-index: 1; @include trans; @include transform(scale(0));
    @include flexbox; @include flow-rn; @include just-center; @include items-center;
    position: absolute; top: 20%; bottom: auto; left: 0; right: 0;
    @include tablet {width: 60%; top: 33%;}
    @include desk {width: 35%;}
    &.active {@include transform(scale(1));}
    .back {@include padbox(0.5em); color: $blue; font-size: 0.9em;
      position: absolute; top: 0.5em; left: 0.5em;
      @include tablet {top: 1em; left: 1em;}
      &::before {content: "\f104"; font-family: $icon; display: inline-block; padding-right: 6px;}
      &:hover {text-decoration: underline;}
    }
  }

  &--validate-number {width: 90%; margin: 0 auto; z-index: 1; @include trans; @include transform(scale(0));
    @include flexbox; @include flow-rn; @include just-center; @include items-center;
    position: absolute; top: 20%; bottom: auto; left: 0; right: 0;
    @include tablet {width: 60%; top: 33%;}
    @include desk {width: 35%;}
    &.active {@include transform(scale(1));}
    .back {@include padbox(0.5em); color: $blue; font-size: 0.9em;
      position: absolute; top: 0.5em; left: 0.5em;
      @include tablet {top: 1em; left: 1em;}
      &::before {content: "\f104"; font-family: $icon; display: inline-block; padding-right: 6px;}
      &:hover {text-decoration: underline;}
    }
  }

  &--validate-token {width: 90%; margin: 0 auto; z-index: 1; @include trans; @include transform(scale(0));
    @include flexbox; @include flow-rn; @include just-center; @include items-center;
    position: absolute; top: 20%; bottom: auto; left: 0; right: 0;
    @include tablet {width: 60%; top: 33%;}
    @include desk {width: 35%;}
    &.active {@include transform(scale(1));}
    .back {@include padbox(0.5em); color: $blue; font-size: 0.9em;
      position: absolute; top: 0.5em; left: 0.5em;
      @include tablet {top: 1em; left: 1em;}
      &::before {content: "\f104"; font-family: $icon; display: inline-block; padding-right: 6px;}
      &:hover {text-decoration: underline;}
    }
  }

  &--content {width: 100%; text-align: center; position: static; @include padbox(1.5em); background-color: white;
    @include tablet {padding: 1em 2em;}
    header {width: 100%; padding: 1em 0;
      i {margin-bottom: 1em;
        svg {height: 80px; display: block;}
      }
      h2 {font-size: 1.5em; font-weight: 300; padding-bottom: 10px;
        @include tablet {font-size: 1.875em;}
      }
    }
    nav {width: 100%; @include flexbox; @include flow-rw; @include just-between; @include items-center;
      a {@include padbox(0.5em); border: 1px solid $blue; width: 100%; margin-bottom: 1em; @include radius(3px);
        @include tablet {width: 46%;}
        &:nth-child(1) {background-color: white; color: $blue;}
        &:nth-child(2) {background-color: $blue; color: white;}
      }
    }
    .session {
      display: flex;
      gap: 5px;
      b {
        display: inline;
      }
      text-align: left;
      @include tablet {
        justify-content: center;
      }
      color: $black;
      font-weight: 400;
      font-size: .75rem;
      margin-bottom: 1em;
      > svg {
        min-width: 16px;
      }
    }

    // --- renewal modal
    // section {width: 100%; background-color: $soft; @include padbox(1.5em);
    //   header {width: 100%; text-align: left;
    //     @include flexbox; @include flow-rw; @include just-between; @include items-center;
    //     i {width: 24px;
    //       svg {@include size(24px, 24px); display: block;}
    //     }
    //     div {width: calc(100% - 24px);
    //       h4 {}
    //       p {}
    //     }
    //   }
    //   ul {
    //     li {
    //       b {}
    //       span {}
    //     }
    //   }
    //   aside {
    //     p {}
    //   }
    // }
  }

  &--content-tipo-compra {width: 100%; text-align: center; position: static; @include padbox(1.5em); background-color: white;
    @include radius(7px);
    @include tablet {padding: 1em 2em;}
    header {width: 100%; padding: 1em 0;
      i {margin-bottom: 1em;
        svg {height: 80px; display: block;}
      }
      h2 {
        font-size: 0.9em;
        text-align: start;
        color: $red;
        font-weight: 400;
        padding-bottom: 10px;
      }
    }
    &-nav {
      width: 100%; @include flow-rw; @include just-between; @include items-center;
      a {@include padbox(0.5em); width: 100%; margin-bottom: 1em; @include radius(5px);
        font-size: 0.9em;
        height: 2.5em;
        font-weight: 400;
        &:nth-child(1) {background-color: $red; color: white;}
        &:nth-child(2) {background-color: $red; color: white;}
      }
    }
  }

  &--content-validate-number {width: 100%; text-align: center;
    box-shadow: none !important; position: static; @include padbox(1.5em); background-color: white;
    @include tablet {padding: 0 !important;}
    @include radius(7px);
    header {width: 100%; padding: 1em 0;
      i {margin-bottom: 1em;
        svg {height: 80px; display: block;}
      }
      h2 {
        font-size: 0.9em;
        text-align: start;
        color: $red;
        font-weight: 400;
        padding-bottom: 10px;
      }
      p {font-size: 0.75em; font-weight: 300; padding-bottom: 5px;
        color: $grey;
        text-align: start;
      }
      span{
        font-weight: 500;
        color: $red;
      }
    }
    form {width: 100%; margin-bottom: 1em;
      text-align: start;
      p {font-size: 0.75em; font-weight: 400; padding-bottom: 5px;
        &::before {content: "* ";}
      }
      b {font-size: 0.75em; font-weight: 300; padding-bottom: 5px;
        margin-top: 0.5em;
        color: $grey;
        text-align: start;
      }
      label {width: 100%; margin-bottom: 0.5em;
        input {
          font-size: 0.9em;
          @include radius(5px);
        }
      }
      > button, > a {width: 100%; @include padbox(0.5em); @include radius(3px);
        text-align: center;
        background-color: $red; color: white; text-align: center;
      }
      > div { text-align: center; margin: 0.5rem 0;
        a { font-size: 0.9rem; color: $blue; cursor: pointer;
          @include hovers { text-decoration: underline; }
        }
      }
      .reenviar {
        color: black;
        font-weight: 300;
        margin-bottom: 2rem;
        span {
          color: $red;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
    nav {
      margin-top: 1em;
      width: 100%; @include flexbox; @include flow-rw; @include just-between; @include items-center;
      a {@include padbox(0.5em); width: 100%; margin-bottom: 0; @include radius(5px);
        @include tablet {width: 46%;}
        height: 2.5em;
        text-align: center;
        &:nth-child(1) {background-color: silver; color: white;}
        &:nth-child(2) {background-color: $blue; color: white;}
      }
      button{@include padbox(0.5em); width: 100%; @include radius(5px);
        height: 2.5em;
        text-align: center;
        @include tablet {width: 46%;}
        background-color: $red; color: white;
      }
    }
  }

  &--geo {width: 90%; background-color: white; @include padbox(1em); margin: 0 auto; z-index: 1;
    @include transform(scale(0)); @include trans;
    position: absolute; top: auto; bottom: auto; left: 0; right: 0;
    @include tablet {padding: 2em; width: 80%;}
    @include desk {width: 70%;}
    &.active {@include transform(scale(1));}
    header {width: 100%; text-align: center; margin-bottom: 1em;
      h2 {font-size: 1.875em; font-weight: 300;}
    }
    form {width: 100%; margin-bottom: 1em;
      @include flexbox; @include flow-rw; @include just-between; @include items-center;
      label {@include selectbox; width: 24%;}
      div {display: inline-block; width: 24%;
        button, a {@include padblock(0.6em); color: white; background-color: $blue; width: 100%; text-align: center; @include radius(3px);}
      }
    }
    aside {width: 100%;
      iframe {width: 100%; height: 16em;
        @include tablet {height: 23em;}
      }
    }
  }

  &--advice {
    &-requested {
      width: 100%;
      span, b { font-size: 0.9rem; margin-bottom: 0.5rem; }
    }
  }

}
.tel-swal {
  .swal2-close {
    color: #da291c !important;
    font-weight: 600;
    font-size: 2rem;
    padding: 0.375rem 0.75rem;
    width: auto;
    height: auto;

    &:focus {
      box-shadow: none;
    }
  }
}
